<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">增加免考勤人员</div>
			<div class="top_flex">
				<div class="search_flex" style="flex: 1">
					<div class="texts">* 选择考勤人员：</div>
					<div class="inputs_box">
						<!-- <input style="width: 100%" type="text" placeholder="请搜索选择免考勤人员" v-model="keyword" /> -->

						<el-input clearable v-model="keyword" placeholder="请搜索选择免考勤人员"
							@keyup.native.enter="do_search_user()"></el-input>
					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="do_search_user()">搜索</div>
				</div>
			</div>
		</div>

		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					搜索结果
				</div>
				<div class="rights">
					<!-- <div class="btns" @click="do_export()">添加考勤规则</div> -->
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="result_list" stripe style="width: 100%" header-cell-class-name="el_table_title" height="">
					<!-- 暂时无需 -->
					<el-table-column prop="name" label="姓名" min-width="120">
						<template slot-scope="scope">
							<el-link type="primary">{{scope.row.name}}</el-link>
						</template>

					</el-table-column>
					<el-table-column prop="depart_title" label="部门" min-width="120"></el-table-column>
					<el-table-column prop="level6" label="岗位" min-width="120"></el-table-column>
					<el-table-column prop="name" label="操作" min-width="120">
						<template slot-scope="scope">
							<el-button v-if="scope.row.is_mkq != 1" type="text"
								@click="do_row_miankaoqin_set(scope.row)">设为免考勤人员</el-button>
							<el-button v-else type="text" @click="do_row_miankaoqin_cancel(scope.row)">取消免考勤人员</el-button>
						</template>
					</el-table-column>
				</el-table>

			</div>
		</div>


		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					免考勤人员
				</div>
				<div class="rights">
					<!-- <div class="btns" @click="do_export()">添加考勤规则</div> -->
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<el-table-column prop="name" label="姓名" min-width="120">
						<template slot-scope="scope">
							<el-link type="primary">{{scope.row.name}}</el-link>
						</template>

					</el-table-column>
					<el-table-column prop="depart_title" label="部门" min-width="120"></el-table-column>
					<el-table-column prop="level6" label="岗位" min-width="120"></el-table-column>
					<el-table-column prop="name" label="操作" min-width="120">
						<template slot-scope="scope">
							<!-- <el-popconfirm confirm-button-text="确认" cancel-button-text="不了" icon="el-icon-info" icon-color="red"
								title="确定删除吗?" @confirm="handleClick('删除', scope.row)">
								<el-button slot="reference" type="text">删除</el-button>
							</el-popconfirm> -->
							<el-button type="text" @click="do_row_delete(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson="count" @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import page from '@/components/page/page.vue'; //分页  统一的

	export default {
		components: {
			page
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				keyword: '',
				result_list: [],


				//提交数据
				forms: {
					is_mkq: 1, //  是否免考勤：-1-否  1-是
				},
				list: [

				],
				pages: {
					page: 1,
					limit: 10
				},
				count: 0,
			};
		},
		computed: {},
		watch: {

		},

		created() {
			this.page_title = this.$route.meta.title;
			this.get_list()
		},
		mounted() {},
		methods: {
			do_search_user() {
				this.$api(
					'getUserList', {
						keyword: this.keyword,
						page: 1,
						limit: 100,
						is_mkq: -1,
					},
					'get'
				).then((res) => {
					this.loading = false;
					if (res.code == 200) {
						// this.result_count = res.data.count;
						this.result_list = res.data.list;
					} else {
						alertErr(res.msg);
					}
				});
			},
			do_row_miankaoqin_set(row) {
				this.$api(
					'editUser', {
						id: row.id,
						is_mkq: 1,
					},
					'post'
				).then((res) => {
					this.loading = false;
					alert(res)
					if (res.code == 200) {
						let index = this.result_list.findIndex(v => v.id == row.id)
						let item = this.result_list[index];
						item.is_mkq = 1;
						this.result_list.splice(index, 1, item);
						this.get_list()
					}
				});
			},
			do_row_miankaoqin_cancel(row) {
				this.$api(
					'editUser', {
						id: row.id,
						is_mkq: -1,
					},
					'post'
				).then((res) => {
					this.loading = false;
					alert(res)
					if (res.code == 200) {
						let index = this.result_list.findIndex(v => v.id == row.id)
						let item = this.result_list[index];
						item.is_mkq = -1;
						this.result_list.splice(index, 1, item);
						this.get_list()
					}
				});
			},

			//接受条数
			currentPage(page) {
				console.log(page);
				this.pages.page = page;
				this.get_list();
			},
			// 接收条数
			row(row) {
				console.log(row);
				this.pages.limit = row;
				this.get_list();
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e);
				this.check_list = e;
			},
			//搜素
			handle_search() {
				this.pages.page = 1;
				this.get_list();
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list();
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms);
				this.loading = true;
				this.$api(
					'getUserList', {
						...this.forms,
						...this.pages
					},
					'get'
				).then((res) => {
					this.loading = false;
					if (res.code == 200) {
						this.count = res.data.count;
						this.list = res.data.list;
					} else {
						alertErr(res.msg);
					}
				});
			},

			do_row_delete(row) {
				this.$confirm('此操作将从免考勤人员中删除该用户, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api("kaoqin/delRule", {
						id: row.id
					}, "post").then((res) => {
						alert(res)
						if (res.code == 200) {
							this.get_list()
						}
					});
				}).catch(() => {

				});
			},
		}
	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #ffffff;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #ffffff;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #e8e8e8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;
						}
					}

					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157de9 0%, #3697fd 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #ffffff;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697fd 100%);
						}
					}
				}
			}
		}

		.bom_box {
			background: #ffffff;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #ffffff;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157de9 0%, #3697fd 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #ffffff;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697fd 100%);
						}
					}
				}
			}

			.bom_box_list {
				width: 1640px;
				// height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}
		}
	}
</style>